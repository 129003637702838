import Cher from '@wearemojo/cher';
import crpc, {
	RequestOptions as crpcRequestOptions,
	snek,
} from '@wearemojo/crpc';
import EventEmitter from 'eventemitter3';

import { BackendConfig } from './BackendConfig';
import BackendSystem from './BackendSystem';
import { makeQueryablePromise } from './promise';
import MojoService from './Service';
import Service from './Service';
import { ServiceParams } from './ServiceParams';
import { ServiceResponses } from './ServiceResponses';
import services from './services';
import MojoServiceVersions from './ServiceVersions';
import {
	CallOptions,
	ClientContext,
	RequestOptions,
	StorageProvider,
} from './types';

export type AuthSession = {
	// TODO: worth naming these more accurately?

	Session: ServiceResponses['authsession']['createSession'];
	User: ServiceResponses['authsession']['attachUserWithAuthzToken'];
};

export type ClientConfig = {
	backendConfig: BackendConfig;
	routingStyle: 'cloudflare' | 'gcr_old' | 'gcr_new';
	additionalHeaders?: Record<string, string>;
	clientId: string;
	clientContext: ClientContext;
	storageProvider?: StorageProvider; // for persisting auth session
	serviceClient?: ServiceClient; // override service client, e.g. testing
};

export type RemakeSessionReason = {
	code: 'logged_out' | 'session_abandoned';
	meta?: Record<string, unknown>;
};

// CURRENT_VERSION is used version the the local client state.If you change it,
//it will drop all previous sessions. This means all users will be logged out.
const CURRENT_VERSION = 1;
const BASE_STORAGE_KEY = '__MOJO_API_CLIENT__';
const END_SESSION_CODES = [
	'cannot_refresh',
	'cannot_use_session',
	'refresh_token_expired',
	'refresh_token_invalidated',
	'session_invalidated',
	'session_mismatch',
	'session_not_found',
];
class ApiClient {
	private backendConfig: BackendConfig;
	private routingStyle: 'cloudflare' | 'gcr_old' | 'gcr_new';
	private additionalHeaders?: Record<string, string>;
	private clientId: string;
	private storageProvider?: StorageProvider;
	private storageKey: string;
	private clientContext: ClientContext;
	private serviceClient: ServiceClient;

	private authSession: AuthSession['Session'];
	private authUser?: AuthSession['User'];

	private attachUserPromises: Record<string, Promise<void>> = {};

	events = new EventEmitter();

	authsession = new services[MojoService.authsession].Client(this); // @TODO replace this with request() call

	request<
		ServiceKey extends Service,
		Endpoint extends keyof ServiceParams[ServiceKey] &
			keyof ServiceResponses[ServiceKey],
		Params extends ServiceParams[ServiceKey][Endpoint],
		Response extends ServiceResponses[ServiceKey][Endpoint],
	>(
		service: ServiceKey,
		endpoint: Endpoint,
		params: Params | undefined = undefined,
		options: RequestOptions = {},
	): Promise<Response> {
		const version = MojoServiceVersions[service];
		const method = snekStr(endpoint as string);
		return this.do(`s-${service}`, version, method, params, options);
	}

	// on the provided `storageProvider`, the `reinitialize`
	// method will be called when the auth session is remade
	private constructor({
		config: {
			backendConfig,
			routingStyle,
			additionalHeaders,
			clientId,
			clientContext,
			storageProvider,
			serviceClient,
		},
		storageKey,
		authSession,
		authUser,
	}: {
		config: Omit<ClientConfig, 'serviceClient'> & {
			serviceClient: ServiceClient;
		};
		storageKey: string;
		authSession: AuthSession['Session'];
		authUser: AuthSession['User'] | undefined;
	}) {
		this.clientId = clientId;
		this.clientContext = clientContext;
		this.backendConfig = backendConfig;
		this.routingStyle = routingStyle;
		this.additionalHeaders = additionalHeaders;
		this.storageProvider = storageProvider;
		this.storageKey = storageKey;
		this.authSession = authSession;
		this.authUser = authUser;
		this.serviceClient = serviceClient;
	}

	static async create(config: ClientConfig) {
		const { system, env } = config.backendConfig;
		let storageKey = BASE_STORAGE_KEY;
		if (system !== BackendSystem.prod || env !== 'prod') {
			// Note: Keys to only contain alphanumeric characters, ".", "-", and "_" (expo-secure-store)
			storageKey += `.${system}.${env}`;
		}

		const serviceClient = config.serviceClient ?? crpcServiceClient;
		const authState = await loadAuthState(config.storageProvider, storageKey);
		const authSession = await ensureAuthSession(
			config,
			serviceClient,
			authState?.authSession,
		);

		const client = new ApiClient({
			config: {
				...config,
				serviceClient,
			},
			storageKey,
			authSession,
			authUser: authState?.authUser,
		});

		await client.syncAndPersistAuthState();

		return client;
	}

	private async syncAndPersistAuthState() {
		console.debug('Api Client: syncAndPersist');
		this.events.emit('authUser', this.authUser);
		this.events.emit('authSession', this.authSession);

		if (!this.storageProvider) {
			return;
		}

		const data = JSON.stringify({
			v: CURRENT_VERSION,
			authSession: this.authSession,
			authUser: this.authUser,
		});

		await this.storageProvider.setItem(this.storageKey, data);
	}

	setContext(context: ClientContext) {
		this.clientContext = context;
	}

	async attachUserToSession(authzToken: string) {
		const { sessionId } = this.authSession;

		const promKey = `authz-token:${authzToken}`;
		if (!this.attachUserPromises[promKey]) {
			this.attachUserPromises[promKey] = (async () => {
				try {
					// @TODO replace this with request() call
					this.authUser = await this.authsession.attachUserWithAuthzToken({
						sessionId,
						authzToken,
						context: this.clientContext,
					});
				} catch (error) {
					if (Cher.isCode(error, ...END_SESSION_CODES)) {
						try {
							await this.remakeSession({
								code: 'session_abandoned',
								meta: {
									reason: 'attach_user_failed',
									error,
								},
							});
						} catch (remakeError) {
							console.warn('Failed to remake session', remakeError);
						}
					}

					throw error;
				}
			})();
		}

		await this.attachUserPromises[promKey];

		await this.syncAndPersistAuthState();
	}

	private clientOptions(overrides?: RequestOptions): crpcRequestOptions {
		const options: RequestOptions = {
			includeAccessToken: true,
			...overrides,
		};

		const headers: Record<string, string> = {
			...this.additionalHeaders,
		};

		const token = options.accessTokenOverride ?? this.authSession.accessToken;
		if (options.includeAccessToken && token) {
			headers.authorization = `bearer ${token}`;
		}

		return {
			timeout: options.timeout,
			headers,
			disableRequestSnek: options.disableRequestSnek,
			disableResponseDesnek: options.disableResponseDesnek,
		};
	}

	async do<TReq, TRes>(
		name: `s-${string}`,
		version: string,
		method: string,
		params: TReq,
		opts?: CallOptions,
	): Promise<TRes> {
		const client = this.serviceClient(
			name,
			version,
			this.backendConfig,
			this.routingStyle,
		);
		const request = () =>
			client<TReq, TRes>(method, params, this.clientOptions(opts));

		// in case we're already in the middle of refreshing
		if (!opts?.skipAuthRefresh) {
			await this.authSessionRefresh;
		}

		try {
			return await request();
		} catch (requestError) {
			if (opts?.skipAuthRefresh || !Cher.isCode(requestError, 'unauthorized')) {
				// TODO: `user_already_attached` actually is totally recoverable
				// ideally we'd have the app not try to store the user ID and instead
				// resolve its current user ID on demand like anything else - in theory,
				// it could just be a normal RTK query
				if (Cher.isCode(requestError, 'user_already_attached')) {
					try {
						await this.remakeSession({
							code: 'session_abandoned',
							meta: {
								reason: 'request_failed',
								error: requestError.code,
							},
						});
					} catch (remakeError) {
						console.warn('Failed to remake session', remakeError);
					}
				}

				throw requestError;
			}

			await this.refreshAuthSession();

			try {
				return await request();
			} catch (retryError) {
				if (Cher.isCode(retryError, 'unauthorized')) {
					try {
						await this.remakeSession({
							code: 'session_abandoned',
							meta: {
								reason: 'request_failed_after_refresh',
								error: retryError,
							},
						});
					} catch (remakeError) {
						console.warn('Failed to remake session', remakeError);
					}
				}

				throw retryError;
			}
		}
	}

	async remakeSession(reason: RemakeSessionReason) {
		console.debug('Api Client: remakeSession', { reason });
		const { sessionId, accessToken } = this.authSession;
		if (sessionId) {
			reason.meta ??= {};
			reason.meta.old_session_id = sessionId ?? null;
		}

		this.events.emit('authError', reason);

		if (sessionId && accessToken) {
			try {
				await this.authsession.endSession(
					{
						sessionId,
						includeChildren: false,
						reason,
					},
					{
						accessTokenOverride: accessToken,
						skipAuthRefresh: true,
					},
				);
			} catch {
				// it's normal for this to fail if the session is already invalidated
			}
		}

		this.authSession = await this.authsession.createSession(
			{
				clientId: this.clientId,
				context: this.clientContext,
				reason,
			},
			{
				includeAccessToken: false,
				skipAuthRefresh: true,
			},
		);

		this.authSessionRefresh = makeQueryablePromise(Promise.resolve());

		this.authUser = undefined;

		// this destroys _all_ the storage
		// in future, if we want to persist something across sessions, it'll need to
		// be handled differently - e.g. a non-encrypted mmkv for that data
		await this.storageProvider?.reinitialize();
		await this.syncAndPersistAuthState();

		return this.authSession;
	}

	private authSessionRefresh = makeQueryablePromise(Promise.resolve());

	// refreshAuthSession returns any existing refresh session promise if it is
	// pending, otherwise will create a new queryable promise that you can await
	// if you want.
	// This is to prevent multiple calls to refreshAuthSession when a group of
	// requests are made.
	private async refreshAuthSession() {
		console.debug('Api Client: refreshSession');
		if (this.authSessionRefresh.isPending()) {
			await this.authSessionRefresh;
			return;
		}

		const refresh = async () => {
			console.debug('Api Client: refreshSession refresh()');
			try {
				this.authSession = await this.authsession.refreshSession(
					{
						clientId: this.clientId,
						context: this.clientContext,
						grantType: 'refresh_token',
						refreshToken: this.authSession.refreshToken,
					},
					{
						includeAccessToken: false,
						skipAuthRefresh: true,
					},
				);
				await this.syncAndPersistAuthState();
			} catch (error) {
				console.error('Failed to refresh auth session', error);

				if (Cher.isCode(error, ...END_SESSION_CODES)) {
					try {
						await this.remakeSession({
							code: 'session_abandoned',
							meta: {
								reason: 'refresh_failed',
								error,
							},
						});
					} catch (remakeError) {
						console.warn('Failed to remake session', remakeError);
					}
				}

				// the throw would be captured by the queryable promise
				// so clear to allow retries
				this.authSessionRefresh = makeQueryablePromise(Promise.resolve());

				throw error;
			}
		};

		return await (this.authSessionRefresh = makeQueryablePromise(refresh()));
	}

	getAuthUser(): Readonly<AuthSession['User']> | undefined {
		return this.authUser;
	}

	getAuthSession(): Readonly<AuthSession['Session']> {
		return this.authSession;
	}
}

async function loadAuthState(
	storageProvider: StorageProvider | undefined,
	storageKey: string,
) {
	if (!storageProvider) {
		return;
	}

	const item = await storageProvider.getItem(storageKey);
	if (!item) {
		return;
	}

	const data = await (async () => {
		try {
			return JSON.parse(item);
		} catch {
			await storageProvider.removeItem(storageKey);
			return;
		}
	})();

	if (!data) {
		return;
	}

	if (data.v !== CURRENT_VERSION) {
		await storageProvider.removeItem(storageKey);
		return;
	}

	// TODO: better data validation on the data json, this is very basic
	// Look into Vod for this!
	if (
		typeof data.authSession !== 'object' ||
		(typeof data.authUser !== 'object' && typeof data.authUser !== 'undefined')
	) {
		console.warn('Local auth state has been mutated');
		await storageProvider.removeItem(storageKey);
		return;
	}

	return {
		authSession: data.authSession as AuthSession['Session'] | undefined,
		authUser: data.authUser as AuthSession['User'] | undefined,
	};
}

async function ensureAuthSession(
	config: ClientConfig,
	serviceClient: ServiceClient,
	authState: AuthSession['Session'] | undefined,
): Promise<AuthSession['Session']> {
	if (authState) {
		return authState;
	}

	const doer = {
		do<TReq, TRes>(
			name: `s-${string}`,
			version: string,
			method: string,
			params: TReq,
			opts?: CallOptions,
		): Promise<TRes> {
			if (opts) {
				throw new Error('Call options not supported');
			}
			const client = serviceClient(
				name,
				version,
				config.backendConfig,
				config.routingStyle,
			);
			return client<TReq, TRes>(method, params, {
				headers: config.additionalHeaders,
			});
		},
	};

	const client = new services[MojoService.authsession].Client(doer);

	return await client.createSession({
		clientId: config.clientId,
		context: config.clientContext,
		reason: { code: 'initial_session' },
	});
}

export type ServiceClient = typeof crpcServiceClient;

const crpcServiceClient = (
	name: `s-${string}`,
	version: string,
	{ system, env, tag }: BackendConfig,
	routingStyle: 'cloudflare' | 'gcr_old' | 'gcr_new',
) => {
	const crpcOptions = { timeout: 15000 };

	switch (routingStyle) {
		case 'cloudflare': {
			// 1 - via Cloudflare w/the worker
			const suffix = tag ? `~${tag}` : '';
			const basePath = `/${env}${suffix}/${name}/${version}`;
			const baseUrl = `https://api.mojo-${system}.dev${basePath}`;
			return crpc(baseUrl, crpcOptions);
		}

		case 'gcr_old': {
			// 2 - straight to Cloud Run, lil service pretending to be the worker 👶
			const prefix = tag ? `${tag}---` : '';
			const basePath = `/${env}/${name}/${version}`;
			const crSlug =
				system === BackendSystem.prod ? 'zbt3dqitlq' : 'iry66wqrta';
			const baseUrl = `https://${prefix}${env}-s-infraserviceproxy-${crSlug}-nw.a.run.app${basePath}`;
			return crpc(baseUrl, crpcOptions);
		}

		case 'gcr_new': {
			// 3 - this is a new style that Cloud Run introduced recently
			// it has the region in the DNS so could route differently 🤷
			const prefix = tag ? `${tag}---` : '';
			const basePath = `/${env}/${name}/${version}`;
			const crSlug =
				system === BackendSystem.prod ? '523117596961' : '771563933862';
			const baseUrl = `https://${prefix}${env}-s-infraserviceproxy-${crSlug}.europe-west2.run.app${basePath}`;
			return crpc(baseUrl, crpcOptions);
		}
	}
};

const snekStr = (str: string) => {
	// @TODO: snek only acts on object keys, can we change that?
	return Object.keys(snek({ [str]: true }))[0]!;
};

export default ApiClient;
